<template>
  <background>
    <v-layout>
      <navigation-bar @toggle-nav="navVisible = !navVisible" />
      <navigation-drawer :model-value="navVisible" />
      <v-main>
        <slot />
      </v-main>
    </v-layout>
  </background>
</template>

<script setup>
import Background from './background.vue'
let navVisible = ref(true)
</script>
