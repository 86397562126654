<template>
  <v-app-bar>
    <template v-slot:prepend>
      <v-app-bar-nav-icon @click="$emit('toggle-nav')" />
    </template>
    <v-toolbar-title>s.Cookies</v-toolbar-title>
    <template v-slot:append>
      <p v-if="username" style="margin-right: 1rem">{{username}}</p>
      <v-btn icon>
        <v-icon>mdi-account</v-icon>
        <v-menu min-width="100%" activator="parent">
          <v-list class="bg-grey-darken-3">
            <v-list-item @click="useRouter().push({path: '/logout'})">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
            <v-list-item @click="useRouter().push({path: '/logout?all'})">
              <v-list-item-title>Logout all sessions</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  emits: ['toggle-nav'],
  data: () => ({
    username: null,
  }),
  async mounted() {
    const {username} = await useNuxtApp().$whoAmI()
    this.username = username
  }
}
</script>
