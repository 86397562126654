<template>
  <v-navigation-drawer>
    <v-list>
      <v-list-item
          v-for="item in items"
          :key="item.title"
          :prepend-icon="item.icon"
          @click="route(item.to)"
          :active="item.regex ? item.regex.test(path) : item.to === path"
      >
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    items: [
      {text: 'Overview', icon: 'mdi-view-dashboard', to: '/'},
      {text: 'Configs', icon: 'mdi-cog', to: '/configs', regex: /^\/(configs|editor\/)/},
      {text: 'Domains', icon: 'mdi-domain', to: '/domains'},
      {text: 'Users', icon: 'mdi-account', to: '/users'},
    ],
    path: '',
    scheduler: null,
  }),
  async beforeMount() {
    this.path = useRoute().path
    const whoAmI = await useNuxtApp().$whoAmI()
    if (!whoAmI.admin) {
      this.items = this.items.filter(item => item.text !== 'Users')
    }
    this.scheduler = setInterval(() => {
      this.path = useRoute().path
    }, 50)
  },
  beforeUnmount() {
    if (this.scheduler) clearInterval(this.scheduler)
  },
  methods: {
    route(path) {
      useRouter().push({path: path})
      this.path = path
    },
  },
}
</script>
